.iconContainer {
  display: flex;
  width: 100%;
  font-size: 2em;
}

.headerIcons {
  min-height: 7.5vh;
  height: 7.5vh;
  z-index: 2;
  max-width: var(--max-width);
}

.icon {
  flex: 1;
  background-color: transparent;
  color: #CCCCCC;
}

.icon.active {
  color: #595aa8;
}

.headerIcon {
  color: gray;
}

.circle {
  border-radius: 50%;
  padding: 0.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.20);
  text-align: center;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.30);
}

#footContainer {
  margin-bottom: 25px;
}

