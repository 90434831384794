.callToActionModal {
  background-color: white;
  position: fixed;
  inset: 0 0 0 0;
  display: flex;
  flex-direction: column;
  color: black;
  z-index: 2;
}

.callToActionContainer{
  display: flex;
  flex: 0.95;
  width: 90%;
  flex-direction: column;
  align-self: center;
  max-width: var(--max-width);
  border: 1px solid;
  border-radius: 20px;
  box-shadow: 4px 4px 5px 4px rgba(0, 0, 0, 0.10);
}

.imageBanner {
  display: flex;
  border-radius: 15px 15px 0px 0px;
  margin-bottom: 0%;
  background-size: 110%;
  background-position: center;
  color: white;
  height: fit-content;
}

.callToActionModalHeader {
  display: flex;
  flex-direction: column;
  margin-left: .7rem;
}

.closeButton {
  margin-top: 2%;
  background-color: black;
  border: 1px solid black;
  border-radius: 50%; 
}

.activityName {
  padding-top: 20%;
  margin-bottom: 10%;
  font-weight: normal;
  font-size: 1.5rem;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.actionContainer {
  border: 1px solid;
  border-color: #e5e5e5;
  padding-bottom: 1rem;
  overflow: hidden;
}

.appButtons {
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: center;
}

.androidButton {
  float: left;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.iosButton {
  float: right;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.description {
  margin-left: 1rem;
  margin-right: 1rem;
}