  .fav-card-container {
    flex: 1;
    flex-direction: column;
    margin: auto;
    width: 90vw;
    max-width: var(--max-width);
    padding-top: 4rem;
    position: absolute;
    inset: 0 0 0 0;
  }

  .fav-card {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 2rem;
    overflow: hidden;
    box-shadow: 10px 8px 10px 5px rgb(145, 144, 144);
    max-height: 9rem;
  }

  .fav-image {
    flex: 0.7;
  }

  .fav-card img {
    width: 100%;
    min-height: 100%;
  }

  .fav-content{
    flex: 1;
    font-size: 1.2rem;
    align-self: center;
  }

  .action-icons {
    flex: 1;
    font-size: 1.3rem;
    align-self: center;
    justify-content: space-around;
  }
  
  .fav-icon {
    margin-left: 10%
  }

  .nothing-saved {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    align-items: center;
  }

  .nothing-saved .no-fav-title {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 2rem;
  }