.matchPageImage {
    position: fixed;
    inset: 0 0 0 0;
    display: flex;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    color: white;
    z-index: 2;
}

.chosenHeader {
    position: fixed;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
}

.chosenText {
    font-size: 3em;
    font-family: 'lobster';
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.buttonsContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40%;
    max-width: var(--max-width);
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: space-around;
    align-items: center;
}

.button{
    border-radius: 25px;
    background: rgb(128, 128, 128, 0.5);
    border: 2px solid white;
    font-size: xx-large;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.matchIcon{
    margin-right: 3%;
}
