.infoModal {
  background-color: white;
  position: fixed;
  inset: 0 0 0 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 5vh;
  z-index: 2;
}

.info-model-body {
  text-align: center;
}

.about-info-title {
  margin-top: .2rem;
  margin-bottom: .2rem;
}

.info-top-description {
  margin-top: .2rem;
}

.infoCardWidth{
  display: flex;
  flex: 1;
  align-self: center;
  flex-direction: column;
  max-width: var(--max-width);
}

.infoCardContainer{
  display: flex;
  flex: 0.9;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 4px 6px -2px rgb(0 0 0 / 40%);
}

.infoImageBanner {
  display: flex;
  flex: 0.7;
  border-radius: 15px 15px 0px 0px;
  background-position: center;
  color: white;
  background-size: cover;
  align-items: flex-end;
  box-shadow: 0 6px 6px 0 #c3c3c3;
  height: fit-content;
}

.info-modal-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: .7rem;
  margin-right: .7rem;
  height: 100%;
  width: 100%;
 text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.infoActivityName {
  align-self: flex-start;
  font-weight: normal;
  font-size: 1.5rem;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.closeInfoButton {
  align-self: flex-end;
  margin-top: 2%;
  background-color: black;
  border: 1px solid black;
  border-radius: 50%; 
}


.info-modal-header>span {
  font-weight: normal;
  font-size: 1.5rem;
}

.infoModalButtonContainer{
  display: flex;
  flex: 0.1;
  align-self: center;
  align-items: center;
  width: 100%;
  margin: 5%;
}