#root {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

* {
  user-select: none;
}

#root>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app {
  overflow: hidden;
}

.app>div {
  display: flex;
  justify-content: center;
  align-items: center;
}
