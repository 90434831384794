.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-right: 10px;
  font-size: 150%;
}

.toggle-switch {
  position: relative;
  width: 55px;
  display: inline-block;
  padding-left: 10px;
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 36px;
  box-sizing: border-box;
}

.inner:before {
  content: "";
  background-color: #595aa8;
}

.inner:after {
  content: "";
  background-color: #bbb;
}

.switch {
  width: 20px;
  margin: 4px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 27px;
  border-radius: 20px;
}

.checkbox:checked+.label .inner {
  margin-left: 0;
}

.checkbox:checked+.label .switch {
  right: 0px;
}