  .filterModal {
    background-color: rgb(255, 255, 255);
    position: absolute;
    inset: 0 0 0 0;
    display: flex;
    flex-direction: column;
    color: rgb(0, 0, 0);
    padding-top: 7.5%;
  }

  .filterCardContainer{
    flex: 0.9;
    flex-direction: column;
    margin: auto;
    width: 90vw;
    max-width: var(--max-width);
  }

  .filterTitle {
    color: #000000;
    text-align: left;
    padding-left: 7.5%;
  }

  .filterGroup {
    margin-top: 0vh;
    margin-bottom: 0vh;
    padding-top: 3vh;
    padding-bottom: 5vh;
    border: 1px solid;
    border-color: #e5e5e5;
  }