:root {
  --max-width: 640px;
}

  .row {
    flex-direction: row !important;
  }
  
  .row>* {
    margin: 5px;
  }

  .tinderCard {
    position: absolute;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }
  
  .cardContainer {
    width: 90vw;
    max-width: var(--max-width);
    flex: 1;
  }
  
  .card {
    position: relative;
    background-color: #fff;
    width: 85vw;
    height:70vh;
    max-width: var(--max-width);
    border: solid 1px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
  }
  
  
  .card h2 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  }
  
  .infoText {
    width: 100%;
    justify-content: center;
    display: flex;
    color: #fff;
    animation-name: popup;
    animation-duration: 800ms;
  }
  
  .buttons {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  
  @media (max-width: 625px) {
    .buttons {
      flex-direction: column;
    }
  }
  
  .buttons button {
    flex-shrink: 0;
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #9198e5;
    transition: 200ms;
    margin: 10px;
    font-weight: bolder;
    width: 160px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
  }
  
  .buttons button:hover {
    transform: scale(1.05);
  }

  .backdrop {
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
  }

  .modal {
    position: relative;
    width: 90vw;
    max-width: var(--max-width);
    z-index: 1040;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 10px 27px -12px rgba(0, 0, 0, 0.30);
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
  }

  .filterModal{
    height: 100%;
    width: 100%;
  }
  
  @keyframes popup {
    0% {
      transform: scale(1, 1)
    }
  
    10% {
      transform: scale(1.1, 1.1)
    }
  
    30% {
      transform: scale(.9, .9)
    }
  
    50% {
      transform: scale(1, 1)
    }
  
    57% {
      transform: scale(1, 1)
    }
  
    64% {
      transform: scale(1, 1)
    }
  
    100% {
      transform: scale(1, 1)
    }
  }