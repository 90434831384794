.favourite-added-notification {
    border-radius: 1.25rem;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    z-index: 1;
    top: 5rem;
    width: 14rem;
    height: 4.3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.7);
}

.favourite-added-message-container {
    color: #424242;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    display: flex;
    align-items: center;
}

.favourite-added-message-container > span {
    margin-left: 1.5rem;
}

.green-tick-container {
    border-radius: 1.25rem 0 0 1.25rem;
    background: #9DFAA8;
    width: 4.1875rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tick-icon-container {
    color: rgba(255, 255, 255, 0.70);
}